@import "./import/default.sass";



.banner {
  height: 470px;
  background-size: cover;
  background-attachment: fixed;
  background-position: center 100%;
  background-repeat: no-repeat;
  &:after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    @include size(100%,100%);
    background-color: linear-gradient(to right, rgba(0,0,0,0.4), tranparent, rgba(0,0,0,0.4));
    background: rgba(0,0,0,0.4);
    background: -moz-linear-gradient(to right, rgba(0,0,0,0.4) 0%, transparent 50%, rgba(0,0,0,0.4) 100%);
    background: -webkit-linear-gradient(to right, rgba(0,0,0,0.4) 0%, transparent 50%, rgba(0,0,0,0.4) 100%);
    background: linear-gradient(to right, rgba(0,0,0,0.4) 0%, transparent 50%, rgba(0,0,0,0.4) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b3dced', endColorstr='#bce0ee',GradientType=0 ); }
  h1 {
    margin: 0;
    position: absolute;
    bottom: 50%;
    right: 15%;
    transform: translateY(50%);
    font-size: 35px;
    letter-spacing: 3px;
    color: #fff;
    z-index: 2;
    font-weight: normal;
    span {
      display: block;
      text-transform: capitalize;
      font-size: 25px;
      margin-top: 20px;
      letter-spacing: 1px; } } }

.page_container {
  &>h2 {
    display: table;
    margin: 0 auto 70px auto;
    font-size: 30px;
    text-align: center;
    padding: 70px 30px 20px 30px;
    color: $color_main;
    border-bottom: solid 1px $color_main; } }


@media screen and (min-width: 768px) {
  body:not(.home) {
    #mobile-nav-toggle {
      top: 200px;
      &:before,&:after {
        height: 100px; }
      &.black {
        top: 300px;
        &:before,&:after {
          height: 200px; } } } } }

@media screen and (max-width: 768px) {
  .banner {
    height: 300px;
    background-attachment: unset;
    h1 {
      font-size: 25px;
      bottom: 45%;
      right: 50%;
      transform: translate(50%,50%);
      text-align: center;
      span {
        font-size: 18px;
        margin-top: 10px; } }
    &:after {
      background-color: rgba(#000,.2); } }

  .page_container {
    &>h2 {
      font-size: 20px;
      padding-top: 40px;
      padding-bottom: 10px;
      margin-bottom: 40px; } } }
