@import "./import/default.sass";

.service_row {
  background-color: #e8e8e8;
  max-width: 1280px;
  margin: 0 auto;
  @include clearfix;
  .service_list {
    margin: 0;
    padding-top: 50px;
    h3 {
      font-size: 25px;
      margin-bottom: 30px;
      margin-top: 0;
      display: table;
      padding-right: 20px;
      padding-bottom: 10px;
      color: $color_main;
      border-bottom: solid 1px $color_main; }
    li {
      list-style-type: none;
      color: $color_main;
      margin-bottom: 10px;
      font-size: 18px; } }
  .content {
    width: 30%; }
  picture {
    width: 70%;
    display: block;
    overflow: hidden;
    img {
      display: block;
      width: 100%;
      @include t(1s);
      filter: grayscale(0.5); }
    &:hover {
      img {
        transform: scale(1.2);
        filter: grayscale(0); } } }
  &.cp {
    picture {
      float: right; }
    .content {
      float: left;
      .service_list {
        float: right;
        padding-right: 15%; } } }
  &.pc {
    picture {
      float: left; }
    .content {
      float: right;
      .service_list {
        float: left;
        padding-left: 15%; } } } }

@media screen and (max-width: 768px) {
  .service_row {
    &.cp,&.pc {
      .content,picture {
        float: none;
        width: 100%;
        max-width: 500px;
        margin: 0 auto; }
      .content {
        .service_list {
          float: none;
          text-align: center;
          padding: 30px 0;
          h3 {
            padding-right: 0;
            display: table;
            margin: 0 auto 30px auto; } } }
      picture {
        img {
          filter: grayscale(0); } } } } }
