$color_main: #474448;
$color_text: #6d696a;


@mixin size($w,$h) {
  width: $w;
  height: $h; }

@mixin t($t) {
  transition-duration: $t; }


@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both; } }

@mixin block_in {
  &:before {
    display: block;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background-color: $color_main;
    z-index: 5;
    @include size(100%,100%);
    transform: scaleX(0); }
  &.block_in {
    img {
      opacity: 1; }
    &:before {
      animation: block_in 1.5s forwards; } } }
