@mixin fadeIn {
  opacity: 0;
  &.-fadeIn {
    opacity: 1!important; } }

@mixin fadeInDown {
  transform: translateY(-100px);
  opacity: 0;
  &.-fadeInDown {
    opacity: 1!important;
    transform: translateY(0); } }

@mixin fadeInUp {
  transform: translateY(100px);
  opacity: 0;
  &.-fadeInUp {
    opacity: 1!important;
    transform: translateY(0); } }

@mixin fadeInLeft {
  transform: translateX(-50px);
  opacity: 0;
  &.-fadeInLeft {
    opacity: 1!important;
    transform: translateX(0); } }

@mixin fadeInRight {
  transform: translateX(50px);
  opacity: 0;
  &.-fadeInRight {
    opacity: 1!important;
    transform: translateX(0); } }

body,a.more,footer#footer .row,.service_row .service_list h3,.service_row picture,#related .related_row .info {
  @include fadeIn; }

#idx_about .info h2,#idx_about .info h3,#idx_about .info p,#idx_case .case_row.lr picture p,.service_row .service_list li,#related .related_row.pi .info p,#related .related_row.pi .info h3 {
  @include fadeInLeft; }

h2.title,#idx_case .case_row .info,.sop_row .tab-contents .sop_row .sop_info {
  @include fadeInUp; }

#idx_case .case_row.rl picture p,#related .related_row.ip .info p,#related .related_row.ip .info h3 {
  @include fadeInRight; }
