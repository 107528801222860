@import "./import/default.sass";

header#header {
  z-index: 10000000;
  width: 100%;
  position: absolute;
  background-color: transparent;
  min-height: unset;
  .site-logo {
    float: none;
    margin: 0;
    display: table;
    a,img {
      display: block;
      @include size(100px,100px); } } }

#header-container {
  width: 95%; }

#main-nav-container {
  display: none; }

#mobile-body-overly {
  background: transparent; }

#mobile-nav {
  position: fixed;
  z-index: 1000000;
  width: 500px;
  background: rgba(#000,.6);
  left: -500px;
  padding-left: 160px;
  padding-top: 100px;
  padding-right: 30px;
  // transition-timing-function: linear
  ul {
    &>li {
      margin-bottom: 15px; }
    li {
      border-bottom: solid 1px transparent;
      &:hover {
        border-bottom-color: #fff; }
      a {
        color: #fff;
        font-family: georgia_0;
        font-size: 18px;
        letter-spacing: 5px;
        span {
          display: block;
          font-size: 14px;
          letter-spacing: 1px;
          margin-top: 10px;
          padding-left: 3px;
          font-weight: 300; } } }
    li.facebook,li.phone,li.fax {
      margin-bottom: 10px;
      padding-left: 20px;
      display: flex;
      align-items: center;
      &:after {
        display: none!important; }
      svg {
        @include size(16px,16px);
        path {
          fill: #fff; } }
      a {
        padding-top: 0;
        padding-bottom: 0;
        letter-spacing: 2px;
        font-size: 12px; }
      span {
        display: none; } }
    li.facebook {
      margin-top: 50px; } } }

#mobile-nav {
  @include t(1s);
  height: 100vh;
  overflow: unset;
  ul,ul li {
    position: unset; }
  .sub-menu {
    position: absolute;
    padding: 0 20px;
    left: 100%;
    top: 0;
    padding-top: 100px;
    width: 200px;
    height: 100vh;
    background-color: rgba(#000,.7);
    li {
      padding: 0;
      a {
        display: flex;
        font-size: 14px;
        &:after {
          display: none; } } } }
  p.copy {
    padding-left: 20px;
    font-size: 12px;
    font-family: georgia_0;
    color: #fff; }
  ul li a {
    &:hover {
      color: #fff; } } }

#mobile-nav ul .menu-item-has-children i {
  display: none; }

#mobile-nav ul .menu-item-active ,#mobile-nav ul>li:hover {
  color: #fff;
  border-bottom-color: #fff; }


@media screen and (min-width: 768px) {
  body.mobile-nav-active {
    #mobile-nav-toggle {
      background-image: url('/wp-content/uploads/icon_cancel.svg');
      i:before {
        color: transparent; }
      &:after,&:before {
        background-color: #fff; } } }
  #mobile-nav-toggle {
    display: block;
    position: fixed;
    z-index: 10000000;
    @include size(auto,100px);
    left: 7.5%;
    top: 300px;
    transform: translate(-50%,0%);
    right: unset;
    margin: 0;
    padding: 0;
    background-size: 40%;
    background-repeat: no-repeat;
    background-position: center center;
    i {
      transform: rotate(-90deg);
      color: #fff;
      text-transform: uppercase;
      @include t(.8s);
      &:before {
        content: "MENU";
        font-family: georgia_0;
        font-size: 17px;
        padding-bottom: 5px; } }
    &:after,&:before {
      content: "";
      display: block;
      @include size(1px,200px);
      background-color: #fff;
      position: absolute;
      right: 50%;
      transform: translateX(50%);
      @include t(.8s); }
    &:after {
      bottom: 100%; }
    &:before {
      top: 100%; }
    &.black {
      i {
        color: $color_main; }
      &:after,&:before {
        background-color: $color_main; } } } }



@media screen and (max-width: 768px) {
  body.mobile-nav-active {
    #mobile-nav-toggle {
      position: fixed; } }
  #mobile-nav {
    width: 100%;
    height: 100vh;
    padding: 110px 30px 0px 30px;
    overflow: scroll;
    left: -100%;
    li {
      border: unset!important; }
    .sub-menu {
      position: unset;
      width: 100%;
      height: auto;
      padding-top: 0; } }
  #mobile-nav-toggle {
    position: absolute;
    z-index: 1000000000;
    margin: 0;
    top: 40px;
    right: 20px;
    i {
      color: #fff; } } }
