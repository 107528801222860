@import "./import/default.sass";

.page_container#organization {
  padding-top: 100px;
  height: 120vh;
  .organization_row {
    max-width: 487px;
    min-width: 487px;
    margin: 0 auto; }
  &.in {
    picture img {
      transform: scale(1); } }
  picture {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-wrap: wrap;
    @include size(100px,100px);
    border-radius: 50%;
    padding: 10px 0;
    border: solid 1px $color_main;
    margin: 0 auto 50px auto;
    background-color: #fff;
    @include t(1s);
    img {
      display: block;
      width: 40%;
      margin: 0 auto;
      @include t(.4s);
      transform: scale(0); }
    p {
      margin: 0;
      text-align: center;
      font-size: 12px;
      color: $color_main;
      letter-spacing: 2px;
      padding-left: 2px;
      width: 100%;
      span {
        display: block; } }
    &:hover {
      img {
        transform: scale(1.2); } }
    &.chairman,&.gm,&.dgm {
      background-color: $color_main;
      p {
        color: #fff; } }
    &.chairman {
      &:after {
        content: "";
        display: block;
        @include size(0,0);
        border: solid 10px transparent;
        border-top-color: $color_main;
        position: absolute;
        top: calc(100px + 20px);
        right: 50%;
        transform: translateX(50%); } }
    &.gm {
      &:after {
        content: "";
        display: block;
        @include size(487px,300px);
        position: absolute;
        top: 49px;
        border-right: 2px solid $color_main;
        border-left: 2px solid $color_main; }
      &:before {
        content: "";
        display: block;
        @include size(485px,2px);
        position: absolute;
        top: 49px;
        right: 50px;
        transform: translateX(50%);
        background-color: $color_main; } }
    &.gm_1 {
      position: absolute;
      left: -50px;
      top: 450px;
      z-index: 2;
      &:before {
        content: "";
        display: block;
        @include size(2px,25px);
        background-color: $color_main;
        position: absolute;
        top: 99px;
        right: 50px;
        transform: translateX(50%); } }
    &.gm_1_1 {
      position: absolute;
      left: -125px;
      top: 600px;
      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 90px;
        bottom: 98px;
        transform: translateX(-50%);
        @include size(80px,28px);
        border-left: solid 2px $color_main;
        border-top: solid 2px $color_main; } }
    &.gm_1_2 {
      position: absolute;
      left: 25px;
      top: 600px;
      &:after {
        content: "";
        display: block;
        position: absolute;
        right: 90px;
        bottom: 98px;
        transform: translateX(50%);
        @include size(80px,28px);
        border-right: solid 2px $color_main;
        border-top: solid 2px $color_main; } }
    &.gm_2 {
      position: absolute;
      right: -50px;
      top: 450px; }
    &.dgm {
      &:before {
        content: "";
        display: block;
        @include size(2px,25px);
        background-color: $color_main;
        position: absolute;
        top: 97px;
        right: 50px;
        transform: translateX(50%); }
      &:after {
        content: "";
        display: block;
        @include size(2px,50px);
        position: absolute;
        bottom: 99px;
        right: 50px;
        transform: translateX(50%);
        background-color: $color_main; } }
    &.dgm_1 {
      position: absolute;
      left: 110px;
      top: 450px;
      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 91px;
        bottom: 99px;
        transform: translateX(-50%);
        @include size(85px,28px);
        border-left: solid 2px $color_main;
        border-top: solid 2px $color_main; } }
    &.dgm_2 {
      position: absolute;
      right: 113px;
      top: 450px;
      &:after {
        content: "";
        display: block;
        position: absolute;
        right: 91px;
        bottom: 99px;
        transform: translateX(50%);
        @include size(85px,28px);
        border-right: solid 2px $color_main;
        border-top: solid 2px $color_main; } } } }

@media screen and (max-width: 768px) {
  .page_container#organization {
    height: 550px;
    padding-top: 0;
    .organization_row {
      position: absolute;
      right: 50%;
      bottom: 65%;
      transform: translate(50%,50%) scale(.7); } } }

@media screen and (max-width: 500px) {
  .page_container#organization {
    height: 450px;
    padding-top: 0;
    .organization_row {
      position: absolute;
      right: 50%;
      bottom: 65%;
      transform: translate(50%,50%) scale(.48); } } }
