@import "./import/default.sass";

@font-face {
  src: url('/assets/fonts/georgia_0.ttf');
  font-family: georgia_0; }
@font-face {
  src: url('/assets/fonts/georgiab_0.ttf');
  font-family: georgiab_0; }
@font-face {
  src: url('/assets/fonts/georgiai_0.ttf');
  font-family: georgiai_0; }
@font-face {
  src: url('/assets/fonts/georgiaz_0.ttf');
  font-family: georgiaz_0; }

@keyframes block_in {
  0% {
    transform-origin: 0% 50%; }
  30% {
    transform-origin: 0 50%;
    transform: scaleX(1); }
  31% {
    transform: scaleX(1);
    transform-origin: 100% 50%; }
  50% {
    transform: scaleX(1);
    transform-origin: 100% 50%; }
  100% {
    transform: scaleX(0);
    transform-origin: 100% 50%; } }

* {
  position: relative; }


body,html {
  // +size(100%,100%)
  overflow-x: hidden; }
a.more {
  display: block;
  color: #fff;
  position: absolute;
  right: 10%;
  bottom: 50px;
  right: 170px;
  @include size(80px,auto);
  text-align: center;
  background-color: rgba(#fff,.3);
  padding: 3px 0;
  font-family: georgia_0;
  letter-spacing: 2px;
  @include t(.5s!important);
  &.black {
    color: #000;
    &:after {
      border-left: solid 1px #000;
      border-right: solid 1px #000; }
    &:before {
      border-top: solid 1px #000;
      border-bottom: solid 1px #000; } }
  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 50%;
    transform: translateY(50%);
    left: 0;
    @include size(100%,calc(100% + 8px));
    border-left: solid 1px #fff;
    border-right: solid 1px #fff; }
  &:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%,50%);
    @include size(calc(100% + 8px),100%);
    border-top: solid 1px #fff;
    border-bottom: solid 1px #fff; }
  &:hover {
    background-color: $color_main;
    color: #fff; } }


h2.title {
  display: table;
  margin: 0 auto;
  text-align: center;
  font-size: 12px;
  color: #474448;
  padding-bottom: 10px;
  margin-bottom: 30px;
  span {
    display: block;
    margin-bottom: 10px;
    font-size: 26px;
    font-family: georgia_0;
    letter-spacing: 2px;
    padding-left: 2px;
    padding-top: 3px; }
  &:after {
    content: "";
    display: table;
    @include size(20px,50px);
    background-image: url('/assets/svg/icon_line_1.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    margin: 0 auto; } }

.flex {
  &.flex {
    display: flex; }
  &.fw {
    flex-wrap: wrap; }
  &.ai {
    align-items: center; }
  &.jc {
    justify-content: center; } }

.fancybox-navigation {
  position: unset; }

.fancybox-container {
  z-index: 10000000; }

.fancybox-button--share {
  display: none; }

@media screen and (min-width:768px) {
  .flex {
    display: flex; } }

