@import "./import/default.sass";

#related {
  padding: 50px 0;
  .container {
    width: 80%; }
  .related_row {
    padding: 50px 0;
    max-width: 1000px;
    margin: 0 auto;
    @include clearfix;
    picture {
      display: block;
      width: 70%;
      overflow: hidden;
      @include block_in;
      img {
        display: block;
        width: 100%;
        transition-delay: .7s;
        opacity: 0; } }
    .info {
      position: absolute;
      max-width: 400px;
      width: 40%;
      padding: 40px;
      bottom: 50%;
      transform: translateY(50%);
      background-color: rgba(#fff,.8);
      z-index: 10;
      p,h3 {
        color: $color_main; }
      h3 {
        margin: 0;
        margin-bottom: 30px; }
      p {
        line-height: 25px;
        letter-spacing: 2px;
        font-size: 16px; }
      .more {
        background-color: $color_main;
        width: 150px;
        padding: 10px 0;
        text-align: center;
        top: 100%;
        right: 10%;
        bottom: unset;
        transform: translateY(-50%);
        &:after,&:before {
          display: none; }
        &:hover {
          transform: scale(1.1) translateY(-50%); } } }
    &.pi {
      picture {
        float: left; }
      .info {
        left: unset;
        right: 0; } }
    &.ip {
      .info {
        left: 0;
        right: unset; }
      picture {
        float: right; } } } }

@media screen and (max-width:768px) {
  #related {
    .container {
      width: 90%; }
    .related_row {
      &.pi,&.ip {
        picture {
          float: none;
          width: 100%; }
        .info {
          position: relative;
          bottom: 0;
          transform: unset;
          width: 100%;
          h3 {
            font-size: 20px;
            text-align: center; }
          p {
            font-size: 12px; }
          .more {
            transform: unset;
            width: 100%;
            right: 0; } } } } } }
