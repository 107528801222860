@import "./import/default.sass";

.contact_us {
  background-size: cover;
  background-position: center 100%;
  padding: 100px 0;
  div.wpforms-container-full .wpforms-form input[type=date], div.wpforms-container-full .wpforms-form input[type=datetime], div.wpforms-container-full .wpforms-form input[type=datetime-local], div.wpforms-container-full .wpforms-form input[type=email], div.wpforms-container-full .wpforms-form input[type=month], div.wpforms-container-full .wpforms-form input[type=number], div.wpforms-container-full .wpforms-form input[type=password], div.wpforms-container-full .wpforms-form input[type=range], div.wpforms-container-full .wpforms-form input[type=search], div.wpforms-container-full .wpforms-form input[type=tel], div.wpforms-container-full .wpforms-form input[type=text], div.wpforms-container-full .wpforms-form input[type=time], div.wpforms-container-full .wpforms-form input[type=url], div.wpforms-container-full .wpforms-form input[type=week], div.wpforms-container-full .wpforms-form select, div.wpforms-container-full .wpforms-form textarea {
    background-color: rgba(#fff,.7); }
  .contact_row {
    max-width: 600px;
    margin-left: 15%; }
  div.wpforms-container-full .wpforms-form .wpforms-field-label {
    color: $color_main;
    font-weight: normal; }
  div.wpforms-container-full .wpforms-form .wpforms-recaptcha-container {
    width: 50px; }
  div.wpforms-container-full .wpforms-form button[type=submit].contact_btn {
    background-color: $color_main;
    color: #fff;
    width: 100%;
    &:hover {
      background-color: #fff;
      color: $color_main; } } }

@media screen and (max-width: 768px) {
  .contact_us {
    padding: 50px 0;
    div.wpforms-container-full .wpforms-form .wpforms-field-label {
      font-size: 14px; }
    .contact_row {
      max-width: 500px;
      width: 80%;
      margin: 0 auto; } } }
