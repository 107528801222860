@import "./import/default.sass";

#idx_about {
  height: 380px;
  img {
    position: absolute;
    right: 50%;
    bottom: 50%;
    @include size(auto,100%);
    transform: translate(50%,50%); }
  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 30px 30px 30px;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 480px;
    background-color: rgba(#474448,.8);
    &:after {
      content: "";
      display: block;
      position: absolute;
      right: 100%;
      bottom: 50%;
      transform: translateY(50%);
      @include size(0,0);
      border-top: solid 190px transparent;
      border-bottom: solid 190px rgba(#474448,.8);
      border-right: solid 40px rgba(#474448,.8);
      border-left: solid 40px transparent; }
    h2,h3,p,a {
      color: #fff;
      max-width: 310px;
      margin: 0; }
    h2 {
      margin-bottom: 40px;
      font-size: 12px;
      display: table;
      width: auto;
      letter-spacing: 2px;
      z-index: 2;
      span {
        display: block;
        font-size: 21px;
        padding-top: 10px;
        font-family: georgia_0; }
      &:after {
        content: "";
        display: block;
        position: absolute;
        right: 230px;
        bottom: -10px;
        @include size(200px,5px);
        background-image: url('/wp-content/uploads/icon_line_2.svg');
        background-size: cover; } }
    h3 {
      font-size: 20px;
      margin-bottom: 15px;
      letter-spacing: 4px; }
    p {
      padding-left: 15px;
      font-size: 12px;
      letter-spacing: 1px; } } }

body.home {
  background-image: url('/wp-content/uploads/body_bg.png');
  background-size: contain; }

#idx_case {
  padding-top: 75px;
  .case_row {
    max-width: 950px;
    margin: 0 auto;
    margin-bottom: 200px;
    &:after {
      content: "";
      display: table;
      clear: both; }
    picture {
      display: block;
      width: 90%;
      max-width: 680px;
      img {
        display: block;
        width: 100%;
        opacity: 0;
        transition-delay: .5s; }
      p {
        font-size: 80px;
        color: #d9e1de;
        position: absolute;
        top: 0;
        line-height: 80px;
        font-family: georgia_0; }
      &:after {
        content: "";
        display: block;
        position: absolute;
        @include size(calc(100% + 20px),calc(100% + 20px));
        right: 50%;
        bottom: 50%;
        transform: translate(50%,50%);
        background-image: url('/wp-content/uploads/icon_border.svg');
        background-size: cover; }
      @include block_in; }
    .info {
      position: absolute;
      top: 90px;
      @include size(370px,auto);
      min-height: 380px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 60px;
      z-index: 5;
      span.case {
        padding: 0 5px;
        font-family: georgia_0;
        background-color: $color_main;
        color: #fff;
        display: table;
        position: absolute;
        left: 50px;
        top: 50px; }
      p {
        line-height: 25px;
        font-size: 12px;
        margin-bottom: 0;
        color: $color_text; }
      h3 {
        margin-bottom: 40px;
        font-size: 21px;
        letter-spacing: 4px;
        color: #242424;
        span {
          display: block;
          font-size: 12px;
          letter-spacing: 1px;
          margin-top: 10px;
          font-family: georgia_0; } }
      h5 {
        font-size: 16px;
        color: $color_main;
 }        // text-shadow: 2px 2px 4px #888
      a.more {
        position: absolute;
        bottom: 40px;
        right: 60px; } }
    &.rl {
      picture {
        float: right;
        p {
          right: calc(100% + 10px); } }
      .info {
        box-shadow: 5px 0px 20px rgba($color_main,.5);
        left: 0; } }
    &.lr {
      picture {
        float: left;
        p {
          left: calc(100% + 10px); } }
      .info {
        box-shadow: -5px 0px 20px rgba($color_main,.5);
        right: 0; } } } }

#idx_classic {
  padding-top: 100px;
  padding-bottom: 50px;
  background-color: #f1f1f1;
  .row {
    max-width: 950px;
    margin: 0 auto; }
  [class*="row_"] {
    float: left;
    display: flex;
    flex-wrap: wrap; }
  [class*="classic_"] {
    float: left;
    list-style-type: none;
    padding: 3px;
    opacity: 0;
    @include t(1s);
    a {
      display: block;
      position: absolute;
      opacity: 0;
      @include t(.5s);
      right: 50%;
      bottom: 50%;
      transform: translate(50%,50%);
      background-color: rgba(#000,.5);
      width: calc(100% - 20px);
      height: calc(100% - 20px);
      .text {
        position: absolute;
        right: 50%;
        bottom: 50%;
        transform: translate(50%,50%);
        h3 {
          color: #fff;
          font-size: 12px;
          text-align: center;
          span {
            display: block;
            margin-top: 20px;
            &:before {
              content: "-";
              position: absolute;
              bottom: calc(100% + 5px);
              left: 0;
              width: 100%;
              text-align: center; } } } } }
    img {
      display: block;
      width: 100%; }
    &:hover {
      a {
        opacity: 1; } } }

  .row_1 {
    width: calc(100% / 4);
    .classic_1 {
      width: calc(100%); } }
  .row_2 {
    width: calc(100% / 4 * 3);
    .classic_1 {
      width: calc(100% / 3); }
    .classic_2 {
      width: calc(100% / 3 * 2); } }

  &.in {
    [class*="classic_"] {
      opacity: 1; } } }


@media screen and (max-width: 768px) {
  #idx_about {
    height: 350px;
    overflow: hidden;
    &>img {
      display: none; }
    .info {
      position: relative;
      width: 100%;
      padding: 0 30px;
      // h2,h3,p
      //   max-width: 100%
      //   padding-left: 0
      //   margin-left: auto
      //   margin-right: auto
      //   display: table
      //   text-align: center
      // h2
      //   &:after
      //     right: 50%
      //     transform: translateX(50%)
      p {
        margin-bottom: 50px; }
      a.more {
        right: 10%; } } }
  #idx_case {
    .container {
      width: 100%; }
    .case_row {
      margin-bottom: 70px;
      .info {
        position: relative;
        left: unset!important;
        right: unset!important;
        top: unset!important;
        margin: 0 auto;
        min-height: 200px;
        padding: 50px 30px 85px 30px;
        width: 90%;
        box-shadow: 5px 0 20px $color_main;
        span.case {
          top: 30px;
          left: 35px; }
        a.more {
          bottom: 30px;
          right: 35px; } }
      picture {
        float: none!important;
        margin: 0 auto 100px auto;
        p {
          font-size: 60px;
          top: 105%;
          left: 0%!important;
          right: unset!important; } } } }


  #idx_classic {
    [class*="classic_"],[class*="row_"] {
      width: 100%!important;
      padding: 0; }
    [class*="classic_"] {
      a {
        opacity: 1; } } } }
