@import "./import/default.sass";

.page_container {
  &#idx_case {
    .case_row {
      .info {
        padding-bottom: 50px;
        padding-top: 50px; }
      // picture

      //   img
      //     opacity: 0
      //     transition-delay: .7s
      //   &.block_in
      //     img
      //       opacity: 1
      //     &:before
 } } }      //       animation: block_in 2s forwards

@media screen and (max-width: 768px) {
  .page_container {
    &#idx_case {
      .case_row {
        .info {
          padding-bottom: 50px; } } } } }



