@import "./import/default.sass";

.sop_row {
  .tab-list {
    max-width: 800px;
    display: table;
    margin: 0 auto 50px auto;
    padding: 50px 0 30px 0;
    li {
      list-style-type: none;
      float: left;
      padding: 10px 10px;
      margin: 0 10px;
      font-size: 20px;
      color: $color_main;
      border-bottom: solid 2px transparent;
      @include t(1s);
      cursor: pointer;
      &.active,&:hover {
        border-bottom-color: $color_main; } } }

  .tab-contents {
    display: table;
    margin: 0 auto;
    padding-bottom: 100px;
    h3 {
      display: table;
      margin: 0 auto;
      background-color: $color_main;
      color: #fff;
      padding: 10px 30px 10px 32px;
      font-size: 20px;
      text-align: center;
      letter-spacing: 2px; }
    .sop_row {
      max-width: 600px; }
    .content {
      @include clearfix;
      display: none;
      &.active {
        display: block; }
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: calc(50% - 1px);
        @include size(2px,100%);
        background-color: $color_main; }
      .sop_info {
        padding-bottom: 30px;
        width: 50%;
        z-index: 2;
        &:nth-child(odd) {
          float: right;
          h4 {
            i {
              padding-left: 30px; }
            span {
              text-align: right;
              padding-right: 30px;
              &:after {
                top: 0;
                right: 0; } } } }
        &:nth-child(even) {
          float: left;
          h4 {
            i {
              text-align: right;
              padding-right: 30px; }
            span {
              padding-left: 30px;
              &:after {
                bottom: 100%;
                left: 0; } } } }
        h4 {
          margin-bottom: 30px;
          i {
            display: block;
            color: $color_main;
            font-size: 20px;
            font-weight: 500;
            text-transform: capitalize;
            letter-spacing: 2px;
            margin-bottom: 5px; }
          span {
            border-top: solid 2px $color_main;
            display: block;
            color: rgb(148,138,84);
            font-weight: normal;
            padding-top: 10px;
            &:after {
              content: "";
              display: table;
              @include size(15px,15px);
              background-color: $color_main;
              position: absolute; } } }
        p {
          padding-left: 30px;
          margin-bottom: 20px;
          color: $color_main;
          &:before {
            content: "-";
            margin-right: 10px; } } } } } }

@media screen and (max-width: 768px) {
  .sop_row {
    .tab-list {
      li {
        font-size: 16px; } }
    .tab-contents {
      h3 {
        font-size: 16px; }
      .content {
        .sop_info {
          h4 {
            span {
              font-size: 16px; } }
          p {
            font-size: 12px; } } } } } }
