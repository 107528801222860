@import "./import/default.sass";

footer#footer {
  background-image: url('/wp-content/uploads/footer_bg.png');
  background-size: cover;
  padding: 50px 0;
  &:after {
    content: "";
    display: block;
    @include size(100%,100%);
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(#fff,.85); }
  .row {
    z-index: 2;
    display: flex;
    margin: 0 auto;
    max-width: 950px;
    width: 90%;
    .map {
      width: 60%;
      iframe {
        width: 100%;
        height: 260px; } }
    .contact_info {
      width: 40%;
      h1 {
        max-width: 115px;
        width: 60%;
        margin: 0 auto 30px auto;
        img {
          display: block;
          width: 100%; } }
      .socail_links,.list {
        margin: 0 auto;
        padding: 0;
        max-width: 300px;
        li {
          list-style-type: none;
          color: $color_main;
          a {
            font-family: georgia_0;
            color: $color_main;
            font-size: 12px; } } }
      .socail_links {
        display: flex;
        border-top: solid 1px $color_main;
        border-bottom: solid 1px $color_main;
        padding: 2px 0;
        margin-bottom: 30px;
        li {
          width: 50%;
          &:first-child {
            border-right: {} }
          a {
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              display: block;
              width: 12px;
              height: auto;
              margin-right: 10px; } } } }
      .list {
        max-width: 300px;
        padding-bottom: 25px;
        padding-left: 37px;
        border-bottom: solid 1px $color_main;
        li {
          display: flex;
          align-items: center;
          margin-bottom: 5px;
          img {
            display: block;
            width: 12px;
            margin-right: 10px; } } }
      p.copy {
        color: $color_main;
        font-size: 12px;
        max-width: 300px;
        margin: 0 auto;
        text-align: center;
        padding: 2px 0;
        border-bottom: solid 1px $color_main;
        font-family: georgia_0; } } }
  .gotop {
    @include size(30px,30px);
    border: solid $color_main 2px;
    z-index: 2;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    right: 5%;
    bottom: 50px;
    &:after {
      content: "";
      display: block;
      @include size(10px,10px);
      border-top: solid 2px $color_main;
      border-right: solid 2px $color_main;
      border-bottom: solid 2px transparent;
      border-left: solid 2px transparent;
      position: absolute;
      bottom: 20%;
      right: 50%;
      transform: translate(50%,0%) rotate(-45deg); }
    &:hover {
      background-color: $color_main;
      &:after {
        border-top-color: #fff;
        border-right-color: #fff; } } } }

@media screen and (max-width: 768px) {
  footer#footer {
    .row {
      display: block;
      .map,.contact_info {
        width: 100%; }
      .map {
        margin-bottom: 30px; } }
    .gotop {
      bottom: 10px; } } }
