@import "./import/default.sass";

.swiper_1 {
  .swiper-slide {
    height: 650px;
    img {
      display: block;
      position: absolute;
      bottom: 50%;
      right: 50%;
      transform: translate(50%,50%);
      @include size(100%,auto); }
    .text {
      position: absolute;
      bottom: 50%;
      right: 20%;
      transform: translateY(50%);
      z-index: 1001;
      opacity: 0;
      @include t(1s);
      transition-delay: 1s;
      h2,p {
        color: #fff;
        margin: 0; }
      h2 {
        display: table;
        font-size: 40px;
        font-weight: 400;
        border-bottom: solid 2px #fff;
        padding-bottom: 10px;
        margin-bottom: 30px;
        span {
          font-size: 12px;
          position: absolute;
          left: 110%;
          line-height: 20px; } } }
    &:after {
      content: "";
      display: block;
      position: absolute;
      z-index: 1000;
      top: 0;
      left: 0;
      @include size(100%,100%);
      background-color: linear-gradient(to right, rgba(#000,.4), tranparent,rgba(#000,.4));
      background: rgba(#000,.4);
      background: -moz-linear-gradient(to right, rgba(#000,.4) 0%, transparent 50%, rgba(#000,.4) 100%);
      background: -webkit-linear-gradient(to right, rgba(#000,.4) 0%,transparent 50%,rgba(#000,.4) 100%);
      background: linear-gradient(to right, rgba(#000,.4) 0%,transparent 50%,rgba(#000,.4) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b3dced', endColorstr='#bce0ee',GradientType=0 ); }
    &.swiper-slide-active {
      .text {
        opacity: 1; } } }
  .swiper-pagination {
    .swiper-pagination-bullet {
      background: #fff;
      opacity: 1;
      border: solid 1px transparent;
      &.swiper-pagination-bullet-active {
        border-color: #fff;
        background: transparent; } } } }

@media screen and (min-width:768px) {
  .swiper_1 {
    .swiper-pagination {
      display: table;
      width: auto;
      bottom: 50%;
      transform: translateY(50%);
      right: 10%;
      left: unset;
      .swiper-pagination-bullet {
        display: block;
        margin-bottom: 10px; } } } }

@media screen and (max-width:768px) {
  .swiper_1 {
    .swiper-slide {
      height: 300px;
      img {
        @include size(100%,auto); }
      .text {
        bottom: 40%;
        right: 10%;
        h2 {
          font-size: 20px;
          margin-bottom: 20px; } }
      &:after {
        content: "";
        display: block;
        position: absolute;
        z-index: 1000;
        top: 0;
        left: 0;
        @include size(100%,100%);
        background: rgba(#000,.4); } } } }

@media screen and (max-width:500px) {
  .swiper_1 {
    .swiper-slide {
      img {
        @include size(auto,100%); } } } }
