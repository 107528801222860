@import "./import/default.sass";

#works {
  max-width: 1280px;
  width: 80%;
  margin: 0 auto;
  padding: 80px 0;
  float: none;
  .works_menu {
    background-color: #e8e8e8;
    margin-bottom: 30px;
    padding-bottom: 50px;
    img {
      display: block;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      opacity: 0.2; }
    .tab-list {
      padding: 0;
      margin: 0;
      z-index: 2; }
    h3 {
      background-color: #333;
      color: #fff;
      text-align: center;
      font-weight: normal;
      display: table;
      padding: 10px 20px;
      margin: 0 auto 0px auto;
      transform: translateY(-10px);
      font-size: 18px; }
    li {
      padding: 15px 0;
      list-style-type: none;
      color: $color_main;
      font-size: 16px;
      text-align: center;
      cursor: pointer;
      &:hover,&.active {
        background-color: $color_main;
        color: #fff; } } }
  .works_container {
    picture {
      display: block;
      @include t(.5s);
      &:hover {
        box-shadow: 3px 3px 10px #333; }
      img {
        display: block;
        width: 100%; } }
    p {
      text-align: center;
      margin-bottom: 0;
      padding: 10px 0 20px 0;
      color: $color_main; } } }

.tab-contents {
  .content {
    display: none;
    &.active {
      display: block; } } }

.lb-container {
  position: unset!important; }

@media screen and (max-width: 768px) {
  #works {
    width: 90%;
    max-width: 500px; } }
